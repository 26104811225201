import React from "react";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@layout/DefaultPageContainer"));
const ResourcesGrid = loadable(() => import("@organisms/ResourcesGrid"));
const PageBuilder = loadable(
  () => import("../components/templates/PageBuilder")
);
const PageHero = loadable(() => import("@organisms/PageHero"));

const ResourcesIndexPage = ({ data, ...rest }) => {
  const { hero, resources, blocks } = data;
  return (
    <PageContainer {...rest} flex={false}>
      <PageHero {...hero} color="teal" />
      <ResourcesGrid resources={resources} />
      <PageBuilder blocks={blocks} />
    </PageContainer>
  );
};

ResourcesIndexPage.defaultProps = {};

export default ResourcesIndexPage;
