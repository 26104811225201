import resolveImage from "./resolveImage";

const resolveResource = r => {
  const { metaImage, image0, collection, metaDescription, title, type } = r;

  const ctaText = {
    events: "Register",
    video: "Watch",
  }[r?.type];

  return {
    ...r,
    topics: collection,
    description: metaDescription,
    tag:
      type === "collections"
        ? title.split(":")[0]
        : collection?.[0]?.title || "Miscellaneous",
    image:
      resolveImage(metaImage) ||
      resolveImage(image0) ||
      resolveImage(collection?.[0]?.metaImage),
    type: r.type || "book",
    ctaText,
  };
};

export default resolveResource;
