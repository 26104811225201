/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
import resolvePageBuilder from "@dataResolvers/resolvePageBuilder";
import PageTemplate from "@pageTemplates/ResourcesIndexPage";
// eslint-disable-next-line no-unused-vars
import PageBuilderFragment from "@fragments/PageBuilderFragment";
import resolveResource from "@dataResolvers/resolveResource";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query ResourcesIndexQuery {
    craft {
      entries(section: ["resources", "collections"]) {
        ... on Craft_collections_default_Entry {
          uid
          title
          uid
          url
          type: sectionHandle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
        ... on Craft_resources_articleEssay_Entry {
          uid
          title
          uid
          url
          external: boolean0
          type: typeHandle
          collection {
            uid
            title
            slug
            ... on Craft_resourceCollections_Category {
              metaImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          audiences {
            uid
            title
            slug
          }
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          categoryLabel: label0
        }
        ... on Craft_resources_video_Entry {
          uid
          title
          uid
          url
          external: boolean0
          type: typeHandle
          collection {
            uid
            title
            slug
            ... on Craft_resourceCollections_Category {
              metaImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          audiences {
            uid
            title
            slug
          }
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          categoryLabel: label0
        }
        ... on Craft_resources_book_Entry {
          uid
          title
          uid
          url
          external: boolean0
          type: typeHandle
          collection {
            uid
            title
            slug
            ... on Craft_resourceCollections_Category {
              metaImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          audiences {
            uid
            title
            slug
          }
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          categoryLabel: label0
        }
        ... on Craft_resources_tool_Entry {
          uid
          title
          uid
          url
          external: boolean0
          type: typeHandle
          collection {
            uid
            title
            slug
            ... on Craft_resourceCollections_Category {
              metaImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          audiences {
            uid
            title
            slug
          }
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          categoryLabel: label0
        }
        ... on Craft_resources_academicArticle_Entry {
          uid
          title
          uid
          url
          external: boolean0
          type: typeHandle
          collection {
            uid
            title
            slug
            ... on Craft_resourceCollections_Category {
              metaImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          audiences {
            uid
            title
            slug
          }
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          categoryLabel: label0
        }
      }
      entry(section: "resourcesIndex") {
        ... on Craft_resourcesIndex_resourcesIndex_Entry {
          heroHeading: heading0
          heroDescriptor: descriptor0
          heroButton: link0 {
            text
            url
          }
          heroSecondaryLink: link1 {
            text
            url
          }
          heroBackgroundImage: image0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          # pagebuilder
          pageBuilder {
            ...PageBuilderFragment
          }
          # Meta
          slug
          url
          title
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry, entries }) => {
  const {
    pageBuilder,
    heroHeading,
    heroDescriptor,
    heroButton,
    heroSecondaryLink,
    heroBackgroundImage,
    // Meta
    url,
    title,
    metaTitle,
    metaDescription,
    metaImage,
    slug,
  } = entry;

  const resources = entries.map(resolveResource).reduce(
    (a, b) => {
      const isInternal = b.external ? "external" : "internal";
      const type = b.type === "collections" ? "collections" : isInternal;
      const cat = [...a[type], b];
      const r = { ...a, [type]: cat };
      if (b.type === "collections") {
        // eslint-disable-next-line prefer-destructuring
        r.tag = title.split(":")[0];
      }
      return r;
    },
    { external: [], internal: [], collections: [] }
  );

  return {
    hero: {
      heading: heroHeading,
      descriptor: heroDescriptor,
      button: heroButton,
      link: heroSecondaryLink,
      image: resolveImage(heroBackgroundImage),
    },
    resources,
    blocks: resolvePageBuilder(pageBuilder),
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
      slug,
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="ResourcesIndexQuery"
    {...props}
  />
);

export default Page;
